'use strict';

var hotpointSliders = $('.hotpoints--slider').each(function () {
  var $artID = $(this).parents('.__js_stats_wrapper').data('id');
  //sprawdzamy, czy jest ustawiony id dla statystyk
  var ga360id = $(this).data('ga360-id');
  if (ga360id == undefined) {
    console.log(
      '%c dodać ga360-id do Slider hotpoint: ' + $(this).data('id'),
      'background: #553311; padding: 3px; color: #ffaaaa'
    );
  } else {
    statElementCollector[ga360id + '-' + $artID] = {
      id: ga360id,
      moduleName: $(this).data('id'),
      viewedCount: 0,
      itemCount: $(this).find('.hotpoints--range').length,
    };
  }
  $(this).statVievedSlide = [];
  //pozycje

  slidePositionVieved[ga360id + '-' + $artID] = {};
  $(this)
    .find('.hotpoints--slider-description')
    .html(
      $(this).find('.hotpoints--range').eq(0).find('.hotpoints--desc').html()
    );
});

$('.hotpoints--slider').on('mouseenter', '._js_switch', function () {
  var current = 0,
    $slider = $(this).parents('.hotpoints--slider');

  current = $(this).parent().index();

  $slider
    .find('.hotpoints--slider-description')
    .html(
      $slider
        .find('.hotpoints--range')
        .eq(current)
        .find('.hotpoints--desc')
        .html()
    );

  $slider
    .find('.hotpoints--range')
    .removeClass('isActive')
    .eq(current)
    .addClass('isActive');

  if (isScrolling) return;

  var $artID = $(this).parents('.__js_stats_wrapper').data('id'),
    $galID = $(this).parents('.hotpoints--slider').data('id'),
    $acId = $(this).parent().find('.hotpoints--point').data('point'),
    ga360id = $(this).parents('.hotpoints--slider').data('ga360-id');

  if (!ga360id) {
    console.log(
      '%c dodać ga360-id do hotpointow!' +
        $galID +
        'background: #3b3b3b; padding: 3px; color: #beeb2f'
    );
  }
  // console.log('art:' + $artID + 'gal:' + $galID + ' / img: ' + $imgID);

  slidePositionVieved[ga360id + '-' + $artID][current] = 1;
  statElementCollector[ga360id + '-' + $artID]['viewedCount'] = Object.keys(
    slidePositionVieved[ga360id + '-' + $artID]
  ).length;

  sendStats('hotpoint', 'click', $artID + ' / ' + $galID, $acId);

  if (!isScrolligFlag) {
    WP.pwp.customAction({
      ga: {
        event: 'content_interaction',
        eventCategory: ga360id,
        moduleName: $galID,
        eventAction: deviceTouch ? 'tap' : 'hover',
      },
    });
  }

  $slider.find('._js_switch').bind('mouseleave', function () {
    current = $(this).parent().index();

    $slider.find('.hotpoints--range').removeClass('isActive');
  });
});
