(function () {
  var loadTeasers = function () {
    var teaserFlag = false;

    $('.teasers--more').on('click', function () {
      if (!teaserFlag && $('.teaser-load').last().data('more') !== undefined) {
        $.ajax({
          url: $('[data-more]').last().data('more'),
          beforeSend: function () {
            teaserFlag = true;
          },
          error: function () {},
          success: function (html) {
            $(html).insertAfter($('[data-more]').last());

            teaserFlag = false;

            if (!$('.teaser-load').last().data('more')) {
              $('.teasers--more').hide();
            }
          },
        });
      }
    });
  };
  if ($('.teaser-load').length) {
    loadTeasers();
  }
})();
